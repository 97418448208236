import React, {Component} from 'react'
import {DPDynamicNumberInput} from '../../common/DPDynamicNumberInput'
import {getDynamicNumberValue, parseDynamicNumberValue, roundToTwoDecimals, sortByReferenceOrderList} from '../util/Utils'
import {DPInput} from '../../common/DPInput'
import {ReactSVG} from 'react-svg'
import DPSelectionDropDown from '../../common/DPSelectionDropDown'
import {BalanceSummary} from './BalanceSummary'
import {loadBalance, saveBalance, saveStateTax} from '../../Api'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import {NON_TAXABLE_ITEMS_REFERENCE_ORDER, SALES_BALANCE_TITLE_LABELS, SALES_BALANCE_ITEM_LABELS } from "../../Constants"

const FIELDS_NAME = {
  CAR_PRICE: 'carPrice',
  DESCRIPTION: 'description',
  AMOUNT: 'amount',
  ADDITIONAL_SERVICES: 'saleAdditionalServices',
  SALE_ACCESSORIES: 'saleAccessories',
  ACCESSORIES: 'accessories',
  ACCESSORY_SERVICE_SUBTOTAL: 'accessoryServiceSubtotal',
  ADDITIONAL_REBATES: 'saleAdditionalRebates',
  ADDITIONAL_REBATES_SUBTOTAL: 'additionalRebateSubtotal',
  SALE_TRADE_INS_SUBTOTAL: 'saleTradeInsSubtotal',
  CASH_PAYMENT: 'cashPayment',
  REGISTRATION_FEES: 'saleRegistrationFees',
  REGISTRATION_FEES_SUBTOTAL: 'saleRegistrationFeesSubtotal',
  ACCESSORY_ID: 'accessoryId',
  SALE_ACCESSORIES_ID: 'saleAccessoriesId',
  QUANTITY: 'quantity',
  UNIT_PRICE: 'unitPrice',
  TYPE_ADDITIONAL: 'typeAdditional',
  SALE_ADDITIONAL_ID: 'saleAdditionalId',
  ID: 'id',
  DEALER_DOCUMENTATION_FEE: 'dealerDocumentationFee'
};

const ADDITIONAL_TYPE = {
  REBATE: 'rebate',
  SERVICES: 'service',
  REGISTRATION_FEES: 'registrationFees'
};

const ErrorToDisplay = {
  [FIELDS_NAME.AMOUNT]: 'Value',
  [FIELDS_NAME.DESCRIPTION]: 'Name',
  [FIELDS_NAME.ACCESSORY_ID]: 'Accessory',
};

function getSaleAdditionalInitial(type) {
  return {typeAdditional: type, description: '', amount: '0.00'}
}

function getSaleAccessoryInitial() {
  return {name: '', unitPrice: '0.00', quantity: 1, isNew: true}
}

function getFieldName(field) {
  switch (field) {
    case FIELDS_NAME.ADDITIONAL_SERVICES:
    case FIELDS_NAME.SALE_ACCESSORIES:
    case FIELDS_NAME.DEALER_DOCUMENTATION_FEE:
      return FIELDS_NAME.ACCESSORY_SERVICE_SUBTOTAL;
    case FIELDS_NAME.ADDITIONAL_REBATES:
      return FIELDS_NAME.ADDITIONAL_REBATES_SUBTOTAL;
    case FIELDS_NAME.CASH_PAYMENT:
      return FIELDS_NAME.SALE_TRADE_INS_SUBTOTAL;
    case FIELDS_NAME.REGISTRATION_FEES:
      return FIELDS_NAME.REGISTRATION_FEES_SUBTOTAL;
  }
}

function getValueSaleTradeIns(saleTradeIns){
  let tradeInAllowance = 0;
  let balanceOwedOn = 0;
  saleTradeIns.forEach(saleTradeIn => {
    tradeInAllowance += saleTradeIn.tradeInAllowance;
    balanceOwedOn += saleTradeIn.balanceOwedOn;
  });
  return {tradeInAllowance,balanceOwedOn}
}

function getSubtotalInitial(items,field){
  let subtotal = 0;
  items.forEach(item => {
    subtotal += item[field];
  });
  if(subtotal === 0 || subtotal === '0')
    return '0.00';

  return subtotal
}

function getSaleAdditional(saleAdditional) {
  const saleAdditionalToSave = [];
  const saleAdditionalToUpdate = [];
  saleAdditional.forEach(additional => {
    if(additional.id){
      saleAdditionalToUpdate.push({id: additional.id, amount: additional[FIELDS_NAME.AMOUNT]})
    }else{
      saleAdditionalToSave.push({typeAdditional: additional[FIELDS_NAME.TYPE_ADDITIONAL], description: additional[FIELDS_NAME.DESCRIPTION] ,
        amount: additional[FIELDS_NAME.AMOUNT]})
    }
  });
  return {saleAdditionalToSave,saleAdditionalToUpdate}
}

function getSaleAccessory(saleAccessories) {
  const saleAccessoryToSave = [];
  saleAccessories.forEach(saleAccessory => {
    saleAccessoryToSave.push({accessoryId: saleAccessory[FIELDS_NAME.ACCESSORY_ID] ,
      quantity: saleAccessory[FIELDS_NAME.QUANTITY], unitPrice: saleAccessory[FIELDS_NAME.UNIT_PRICE]})
  });
  return saleAccessoryToSave
}

function getErrorSaleAdditional(saleAdditional) {
  const errors = [];
  saleAdditional.forEach((additional,index) => {
    errors[index] = {};
    if(!additional[FIELDS_NAME.ID]) {
      if (!additional[FIELDS_NAME.DESCRIPTION])
        errors[index][FIELDS_NAME.DESCRIPTION] = true;
      if (!additional[FIELDS_NAME.AMOUNT])
        errors[index][FIELDS_NAME.AMOUNT] = true;
    }
  });

  return errors;
}

function getErrorSaleAccessory(saleAccessories) {
  const errors = [];
  saleAccessories.forEach((saleAccessory,index) => {
    errors[index] = {};
    if(!saleAccessory[FIELDS_NAME.ACCESSORY_ID])
      errors[index][FIELDS_NAME.ACCESSORY_ID] = true;
  });

  return errors;
}

function validateError(error) {
  let hasError = false;
  for(const key in error){
    error[key].forEach(e => {
      if(e[FIELDS_NAME.DESCRIPTION] || e[FIELDS_NAME.AMOUNT] || e[FIELDS_NAME.ACCESSORY_ID]){
        hasError = true;
      }
    })
  }
  return hasError;
}

export function getValueView(value) {
  if(typeof value === "number") {
    return (value).toFixed(2)
  }else{
    return value
  }
}

function formatValue(value) {
  return value ? parseFloat(value.toString().replace(',','')).toFixed(2) : '0.00';
}

function formatKeyOnList(tempList, key) {
  return tempList.map(el => {
    return ({
      ...el,
      [key]: parseFloat(el[key]).toFixed(2)
    })
  })
}


export default class BalanceDetail extends Component{

  constructor(props) {
    super(props);
    this.state = {
      carPrice: 0.00,
      dealerDocumentationFee: '',
      saleAdditionalServices: [],
      saleAdditionalRebates: [],
      saleAccessories: [],
      accessories: [],
      accessoryServiceSubtotal: 0,
      balanceDue: 0,
      additionalRebateSubtotal: 0.00,
      saleTradeIns: [],
      saleTradeInsSubtotal: 0,
      cashPayment: '',
      saleRegistrationFees: [],
      saleRegistrationFeesSubtotal: 0,
      error:{},
      saleAccessoriesId: [],
      saleAdditionalId: [],
      stateTaxPercentage: null,
      saleStateTax: null,
      isTaxExempt: false,
    }
  }

  componentDidMount() {
    const {saleId} = this.props.params;

    // Showing spinner
    this.setState({ isLoadingPage: true })

    loadBalance(saleId).then(response => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {saleBalance:{carPrice,cashPayment,dealerDocumentationFee,saleAccessories,saleAdditional,saleTradeIns,accessories}} = data;
        const saleAdditionalServices = saleAdditional.filter(ad => { return ad.typeAdditional === ADDITIONAL_TYPE.SERVICES});
        const saleAdditionalRebates = saleAdditional.filter(ad => { return ad.typeAdditional === ADDITIONAL_TYPE.REBATE});
        const saleRegistrationFees = saleAdditional.filter(ad => { return ad.typeAdditional === ADDITIONAL_TYPE.REGISTRATION_FEES});

        const accessoryServiceSubtotal = this.getAccessoryServiceSubtotalInitial(saleAdditionalServices,saleAccessories,dealerDocumentationFee);
        const additionalRebateSubtotal = getSubtotalInitial(saleAdditionalRebates,FIELDS_NAME.AMOUNT);
        const {tradeInAllowance,balanceOwedOn} =  getValueSaleTradeIns(saleTradeIns);
        const saleRegistrationFeesSubtotal = getSubtotalInitial(saleRegistrationFees,FIELDS_NAME.AMOUNT);
        const saleTradeInsSubtotal = tradeInAllowance - balanceOwedOn + cashPayment;
        const balanceDue = carPrice + accessoryServiceSubtotal + additionalRebateSubtotal;

        const formattedCarPrice = parseFloat(carPrice).toFixed(2)
        const formattedCashPayment = parseFloat(cashPayment).toFixed(2)
        const formattedDealerDocFee = parseFloat(dealerDocumentationFee).toFixed(2)
        const formattedSaleAdditionalServices = formatKeyOnList(saleAdditionalServices, FIELDS_NAME.AMOUNT)
        const formattedSaleAccessories = formatKeyOnList(saleAccessories, FIELDS_NAME.UNIT_PRICE)
        const formattedAccessoryServiceSubtotal = parseFloat(accessoryServiceSubtotal).toFixed(2)
        const formattedSaleAdditionalRebates = formatKeyOnList(saleAdditionalRebates, FIELDS_NAME.AMOUNT)
        const formattedAdditionalRebateSubtotal = parseFloat(additionalRebateSubtotal).toFixed(2)
        const formattedSaleRegistrationFees = formatKeyOnList(saleRegistrationFees, FIELDS_NAME.AMOUNT)
        const formattedSaleRegistrationFeeSubtotal = parseFloat(saleRegistrationFeesSubtotal).toFixed(2)
        const formattedTradeInAllowance = parseFloat(tradeInAllowance).toFixed(2)
        const formattedBalanceOwedOn = parseFloat(balanceOwedOn).toFixed(2)
        const formattedSaleTradeInsSubtotal = parseFloat(saleTradeInsSubtotal).toFixed(2)

        this.setState(
          {
            carPrice: formattedCarPrice,
            cashPayment: formattedCashPayment,
            dealerDocumentationFee: formattedDealerDocFee,
            accessoryServiceSubtotal: formattedAccessoryServiceSubtotal,
            additionalRebateSubtotal: formattedAdditionalRebateSubtotal,
            saleTradeInsSubtotal: formattedSaleTradeInsSubtotal,
            tradeInAllowance: formattedTradeInAllowance,
            balanceOwedOn: formattedBalanceOwedOn,
            saleRegistrationFeesSubtotal: formattedSaleRegistrationFeeSubtotal,
            saleAdditionalServices: formattedSaleAdditionalServices,
            saleAdditionalRebates: formattedSaleAdditionalRebates,
            saleRegistrationFees: sortByReferenceOrderList(formattedSaleRegistrationFees, NON_TAXABLE_ITEMS_REFERENCE_ORDER),
            saleAccessories: formattedSaleAccessories,
            accessories,
            balanceDue,
            isLoadingPage: false,
            stateTaxPercentage: response.data.stateTaxPercentage,
            saleStateTax: response.data.saleStateTax
          }
        )
      }else{
        console.error(responseMessage)
        this.setState({ isLoadingPage: false })
      }
    })
  }

  getAccessoryServiceSubtotalInitial = (saleAdditionalServices,saleAccessories,dealerDocumentationFee) => {
    let accessoryServiceSubtotal = parseDynamicNumberValue(dealerDocumentationFee);
    accessoryServiceSubtotal += parseDynamicNumberValue(getSubtotalInitial(saleAdditionalServices,FIELDS_NAME.AMOUNT));
    saleAccessories.forEach(saleAccessory => {
      accessoryServiceSubtotal += saleAccessory.unitPrice * saleAccessory.quantity;
    });
    return accessoryServiceSubtotal;
  };

  onChangeFieldValue = (field,modelValue,viewValue,evt) => {
    evt.stopPropagation();
    const valueOld = this.state[field];
    this.setState({[field]: getDynamicNumberValue(modelValue,viewValue)}, () => {
      if(field === FIELDS_NAME.CASH_PAYMENT || field === FIELDS_NAME.DEALER_DOCUMENTATION_FEE){
        let value = modelValue - parseDynamicNumberValue(valueOld);
        this.sumTotal(getFieldName(field),value)
      }
    })
  };

  onBlurFieldValue = (field,evt) => {
    evt.stopPropagation();
    const value = evt.target.value ? formatValue(evt.target.value) : '0.00';
    this.setState({[field]: value})
  };

  onChangeAdditional = (field,key,index,evt) => {
    evt.stopPropagation();
    const attribute = this.state[field];
    const attributeToUpdate = [...attribute];
    attributeToUpdate[index][key] = evt.target.value;
    this.setState({[field]: attributeToUpdate})
  };

  onChangeAdditionalFieldValue = (field,key,index,modelValue,viewValue,evt) => {
    evt.stopPropagation();
    const attribute = this.state[field];
    let attributeToUpdate = [...attribute];
    const valueOld = attributeToUpdate[index][key];
    attributeToUpdate[index][key] = getDynamicNumberValue(modelValue,viewValue);
    this.setState({[field]: attributeToUpdate}, () => {
      if(key === FIELDS_NAME.AMOUNT){
        let value = roundToTwoDecimals(modelValue - parseDynamicNumberValue(valueOld));
        this.sumTotal(getFieldName(field),value)
      }
    })
  };

  onBlurAdditionalFieldValue = (field, key, index, evt) => {
    evt.stopPropagation();
    const attribute = this.state[field];
    let attributeToUpdate = [...attribute];
    attributeToUpdate[index][key] = evt.target.value ? formatValue(evt.target.value) : '0.00';
    this.setState({[field]: attributeToUpdate})
  };

  deleteAdditional = (field,index,evt) => {
    evt.stopPropagation();
    const attribute = this.state[field];
    const attributeToUpdate = [...attribute];
    const item = attributeToUpdate.splice(index,1);
    let state = {[field]: attributeToUpdate};
    if(field === FIELDS_NAME.SALE_ACCESSORIES && !item[0].isNew){
      const {saleAccessoriesId} = this.state;
      state[FIELDS_NAME.SALE_ACCESSORIES_ID] = [...saleAccessoriesId,item[0][FIELDS_NAME.ACCESSORY_ID]];
    }
    if(item[0][FIELDS_NAME.ID] && (field === FIELDS_NAME.ADDITIONAL_SERVICES || field === FIELDS_NAME.ADDITIONAL_REBATES ||
      field === FIELDS_NAME.REGISTRATION_FEES)){
      const {saleAdditionalId} = this.state;
      state[FIELDS_NAME.SALE_ADDITIONAL_ID] = [...saleAdditionalId,item[0][FIELDS_NAME.ID]];
    }
    this.setState(state, () => {
      let value = 0;
      if(field === FIELDS_NAME.SALE_ACCESSORIES)
        value = -1 * item[0].unitPrice * item[0].quantity;
      else if(field === FIELDS_NAME.ADDITIONAL_SERVICES || field === FIELDS_NAME.ADDITIONAL_REBATES || field === FIELDS_NAME.REGISTRATION_FEES)
        value = -1 * item[0].amount;
      this.sumTotal(getFieldName(field),value)
    })
  };

  addAdditional = (field,item,evt) => {
    evt.stopPropagation();
    const attribute = this.state[field];
    const attributeToUpdate = [...attribute];
    attributeToUpdate.push(item);
    this.setState({[field]: attributeToUpdate})
  };

  getOptions = (field) => {
    const options = this.state[field];
    return options.map(op => {return {id: op.id, displayValue: op.name}})
  };

  handleQuantityChange = (index,quantity,evt) => {
    evt.stopPropagation();
    const {saleAccessories} = this.state;
    const saleAccessoriesToUpdate = [...saleAccessories];
    const value = parseFloat(saleAccessoriesToUpdate[index].unitPrice) * quantity;
    saleAccessoriesToUpdate[index].quantity = saleAccessoriesToUpdate[index].quantity + quantity;
    this.setState({saleAccessories: saleAccessoriesToUpdate}, () => {
      this.sumTotal(FIELDS_NAME.ACCESSORY_SERVICE_SUBTOTAL,value)
    })
  };

  handleAccessorySelect = (id,index) => {
    const {saleAccessories,accessories} = this.state;
    const accessoryFound = accessories.find(accessory => { return accessory.id === id});
    const saleAccessoriesToUpdate = [...saleAccessories];
    saleAccessoriesToUpdate[index].accessoryId = id;
    saleAccessoriesToUpdate[index].name = accessoryFound.name;
    const valueOld = saleAccessoriesToUpdate[index].unitPrice * saleAccessoriesToUpdate[index].quantity;
    saleAccessoriesToUpdate[index].unitPrice = formatValue(accessoryFound.unitPrice);
    saleAccessoriesToUpdate[index].quantity = 1;
    this.setState({saleAccessories: saleAccessoriesToUpdate}, () => {
      this.sumTotal(FIELDS_NAME.ACCESSORY_SERVICE_SUBTOTAL,accessoryFound.unitPrice - valueOld)
    })
  };

  sumTotal = (field,value) => {
    this.setState(prevState => ({[field]: formatValue(parseDynamicNumberValue(prevState[field]) + value)}))
  };

  save = () => {
    const error = this.generateErrorMessage();
    // Showing spinner
    this.setState({ isLoadingPage: true })

    if(!validateError(error)){
      const {carPrice,cashPayment,saleAdditionalServices,saleAdditionalRebates,saleRegistrationFees,
        saleAccessories,saleAccessoriesId,saleAdditionalId,dealerDocumentationFee, stateTaxPercentage, isTaxExempt, saleStateTax} = this.state;
      const sale = {};
      if(carPrice !== '')
        sale.carPrice = carPrice;
      if(cashPayment !== '')
        sale.cashPayment = cashPayment;
      if(dealerDocumentationFee !== '')
        sale.dealerDocumentationFee = dealerDocumentationFee;
      const saleAdditional = getSaleAdditional([...saleAdditionalServices,...saleAdditionalRebates,...saleRegistrationFees]);
      const saleAccessory = getSaleAccessory(saleAccessories);
      const data = {sale,...saleAdditional,saleAccessory,saleAccessoriesId,saleAdditionalId};
      const {saleId} = this.props.params;
      saveBalance(saleId,data).then(response => {
        const {responseCode, responseMessage} = response
        if(responseCode === SUCCESSFUL_CODE){
          this.setState({ isLoadingPage: false })
          this.props.goToSaleBoard('balanceDetail');
        }else{
          console.error(responseMessage)
          this.setState({ isLoadingPage: false })
        }
      })

      this.setState({ isLoadingPage: true })
      const tax = isTaxExempt
        ? 0
        : (saleStateTax !== null
          ? saleStateTax
          : stateTaxPercentage)
      // if(isTaxExempt || stateTaxPercentage !== saleStateTax){
        saveStateTax(saleId,{stateTax: tax}).then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE){
            this.setState({ isLoadingPage: false })
          } else {
            this.setState({ isLoadingPage: false })
            console.error(responseMessage)
          }
        })
      // }
    }else{
      this.setState({
        error,
        isLoadingPage: false,
      })
    }
  };

  getErrorMessage = (field,key,index) => {
    const {error} = this.state;
    const attribute = error[field];
    let errorMessage = '';
    if(attribute && attribute[index] && attribute[index][key]){
      errorMessage = `${ErrorToDisplay[key]} is required`;
    }
    return errorMessage;
  };

  generateErrorMessage = () => {
    const {saleAccessories,saleAdditionalServices,saleAdditionalRebates,saleRegistrationFees} = this.state;
    const errorSaleAccessory = getErrorSaleAccessory(saleAccessories);
    const errorSaleAdditional = getErrorSaleAdditional(saleAdditionalServices);
    const errorSaleAdditionalRebates = getErrorSaleAdditional(saleAdditionalRebates);
    const errorSaleRegistrationFees = getErrorSaleAdditional(saleRegistrationFees);
    return {[FIELDS_NAME.ADDITIONAL_SERVICES]: errorSaleAdditional,[FIELDS_NAME.SALE_ACCESSORIES]: errorSaleAccessory,
      [FIELDS_NAME.ADDITIONAL_REBATES]:errorSaleAdditionalRebates,[FIELDS_NAME.REGISTRATION_FEES]:errorSaleRegistrationFees}
  };

  setIsTaxExempt = (isTaxExempt) => {
    this.setState({isTaxExempt: isTaxExempt})
  }
  setTax = (tax) => {
    this.setState({saleStateTax: tax})
  }

  render() {
    const {carPrice,dealerDocumentationFee,saleAdditionalServices,saleAccessories,accessoryServiceSubtotal,saleAdditionalRebates,
      additionalRebateSubtotal,tradeInAllowance,balanceOwedOn,cashPayment,saleTradeInsSubtotal,saleRegistrationFees,
      saleRegistrationFeesSubtotal, isLoadingPage, isTaxExempt, saleStateTax, stateTaxPercentage} = this.state;

    const {readOnly} = this.props.location.state || {};

    // Showing spinner
    if (isLoadingPage) {
      return (
        <div ref={this.props.ref} className={'sale-balance-container'}>
          <div className={'balance-body'} style={{height: "100%"}}>
            <div className="loader-container">
              <div className="loader"/>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div ref={this.props.ref} className={'sale-balance-container'}>
        <div className={'balance-body'}>
          <div className={'balance-price'}>
            <h3>Price</h3>
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                title={'Cash Price Vehicle'}
                id={'input-cash-price'}
                value={carPrice}
                onChange={(evt, modelValue, viewValue) => this.onChangeFieldValue(FIELDS_NAME.CAR_PRICE, modelValue,viewValue,evt)}
                onBlur={(evt) => this.onBlurFieldValue(FIELDS_NAME.CAR_PRICE,evt)}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                withSymbol={true}
                disabled={readOnly}
                classWrapperInput={readOnly ? 'input-disabled' : ''}
                canSelectText={true}
                dataTest={'cash-price-input'}
              />
            </div>
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                classWrapperInput={'input-disabled'}
                title={'Subtotal'}
                id={'input-subtotal'}
                value={carPrice}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={true}
                withSymbol={true}
                classTitle='bold-label'
              />
            </div>
          </div>
          <div className={'balance-price'}>
            <h3>Accessories + Services</h3>
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                id={'input-documentation-fee'}
                classWrapperInput={readOnly ? 'input-disabled' : ''}
                title={SALES_BALANCE_ITEM_LABELS.DEALER_DOCUMENTATION_FEE}
                value={dealerDocumentationFee}
                onChange={(evt, modelValue, viewValue) => this.onChangeFieldValue(FIELDS_NAME.DEALER_DOCUMENTATION_FEE, modelValue,viewValue,evt)}
                onBlur={(evt) => this.onBlurFieldValue(FIELDS_NAME.DEALER_DOCUMENTATION_FEE,evt)}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={readOnly}
                withSymbol={true}
                canSelectText={true}
              />
            </div>
            {
              saleAdditionalServices.map((additional,index) => {
                const {id,description, amount} = additional;
                return(
                  <div key={index} className={'balance-row'}>
                    {
                      !id ?
                        <DPInput className={'accessory-desc'}
                                 id={'id'}
                                 type={'text'}
                                 value={description}
                                 onChange={(evt) => this.onChangeAdditional(FIELDS_NAME.ADDITIONAL_SERVICES,FIELDS_NAME.DESCRIPTION,index,evt)}
                                 errorMessage={this.getErrorMessage(FIELDS_NAME.ADDITIONAL_SERVICES, FIELDS_NAME.DESCRIPTION, index)}
                        />
                                 : null
                    }
                    <DPDynamicNumberInput
                      className={id ? 'col25' : 'accessory-amount'}
                      id={`input-service-${index}`}
                      title={id ? description : null}
                      value={amount}
                      onChange={(evt, modelValue, viewValue) =>
                        this.onChangeAdditionalFieldValue(FIELDS_NAME.ADDITIONAL_SERVICES,FIELDS_NAME.AMOUNT,index,modelValue,viewValue,evt)}
                      onBlur={(evt) => this.onBlurAdditionalFieldValue(FIELDS_NAME.ADDITIONAL_SERVICES,FIELDS_NAME.AMOUNT,index,evt)}
                      thousand={true}
                      integer={8}
                      separator={'.'}
                      fraction={2}
                      placeHolder={'0.00'}
                      withSymbol={true}
                      errorMessage={this.getErrorMessage(FIELDS_NAME.ADDITIONAL_SERVICES,FIELDS_NAME.AMOUNT,index)}
                      disabled={readOnly}
                      classWrapperInput={readOnly ? 'input-disabled' : ''}
                      canSelectText={true}
                    />
                    {
                      !readOnly && (
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                  className="icon-delete-content" beforeInjection={ svg => svg.classList.add("icon-delete")}
                                  onClick={(evt) =>
                                    this.deleteAdditional(FIELDS_NAME.ADDITIONAL_SERVICES,index, evt)}
                        />
                      )
                    }
                  </div>
                )
              })
            }
            {
              !readOnly && (
                <div className={'balance-row'}>
                  <button className={'btn-primary btn-add-accessory'}
                          onClick={(evt) =>
                            this.addAdditional(FIELDS_NAME.ADDITIONAL_SERVICES, getSaleAdditionalInitial(ADDITIONAL_TYPE.SERVICES),evt)}>
                    + Add Service
                  </button>
                </div>
              )
            }
            {
              saleAccessories.map((saleAccessory,index) => {
                const {accessoryId,name,unitPrice,quantity} = saleAccessory;
                return(
                  <div key={index} className={'balance-row'}>
                    <DPSelectionDropDown
                      className={'balance-select'}
                      //label={name}
                      id={`selectSaleAccessory${index}`}
                      options={this.getOptions(FIELDS_NAME.ACCESSORIES)}
                      value={accessoryId}
                      onClick={id => this.handleAccessorySelect(id,index)}
                      errorMessage={this.getErrorMessage(FIELDS_NAME.SALE_ACCESSORIES,FIELDS_NAME.ACCESSORY_ID,index)}
                      disabled={!!accessoryId}
                    />
                    <div className={'sale-accessory-quantity'}>
                      <button className={'btn-primary btn-add-accessory'}
                              disabled={quantity === 1 || readOnly}
                              onClick={(evt) =>
                                this.handleQuantityChange(index,-1,evt)}>
                        <span>-</span>
                      </button>
                      <span>{quantity}</span>
                      <button className={'btn-primary btn-add-accessory  btn-add-quantity'}
                              disabled={readOnly}
                              onClick={(evt) =>
                                this.handleQuantityChange(index,1,evt)}>
                        <span>+</span>
                      </button>
                    </div>
                    <DPDynamicNumberInput
                      className={'accessory-amount number-quantity'}
                      classWrapperInput={'input-disabled'}
                      value={(unitPrice * quantity).toFixed(2)}
                      thousand={true}
                      integer={8}
                      separator={'.'}
                      fraction={2}
                      placeHolder={'0.00'}
                      disabled={true}
                      withSymbol={true}
                    />
                    {
                      !readOnly && (
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                  className="icon-delete-content" beforeInjection={ svg => svg.classList.add("icon-delete")}
                                  onClick={(evt) =>
                                    this.deleteAdditional(FIELDS_NAME.SALE_ACCESSORIES,index, evt)}
                        />
                      )
                    }
                  </div>
                )
              },)
            }
            {
              !readOnly && (
                <div className={'balance-row'}>
                  <button className={'btn-primary btn-add-accessory'}
                          onClick={(evt) =>
                            this.addAdditional(FIELDS_NAME.SALE_ACCESSORIES, getSaleAccessoryInitial(),evt)}>
                    + Add Accessory
                  </button>
                </div>
              )
            }
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                classWrapperInput={'input-disabled'}
                title={'Subtotal'}
                id={'input-accessory-subtotal'}
                value={accessoryServiceSubtotal}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={true}
                withSymbol={true}
                classTitle='bold-label'
              />
            </div>
          </div>
          <div className={'balance-price'}>
            <h3>Rebates</h3>
            {
              saleAdditionalRebates.map((saleAdditionalRebate,index) => {
                const {id,description, amount} = saleAdditionalRebate;
                return(
                  <div key={`saleAdditionalRebate${index}`} className={'balance-row'}>
                    {
                      !id ?
                        <DPInput className={'accessory-desc'}
                                 id={'id'}
                                 type={'text'}
                                 value={description}
                                 onChange={(evt) => this.onChangeAdditional(FIELDS_NAME.ADDITIONAL_REBATES,FIELDS_NAME.DESCRIPTION,index,evt)}
                                 errorMessage={this.getErrorMessage(FIELDS_NAME.ADDITIONAL_REBATES,FIELDS_NAME.DESCRIPTION,index)}/> :
                        null
                    }
                    <DPDynamicNumberInput
                      className={id ? 'col25' : 'accessory-amount'}
                      title={id ? description : null}
                      id={'input-manufacturer'}
                      value={amount}
                      onChange={(evt, modelValue, viewValue) =>
                        this.onChangeAdditionalFieldValue(FIELDS_NAME.ADDITIONAL_REBATES,FIELDS_NAME.AMOUNT,index,modelValue,viewValue,evt)}
                      onBlur={(evt) => this.onBlurAdditionalFieldValue(FIELDS_NAME.ADDITIONAL_REBATES,FIELDS_NAME.AMOUNT,index,evt)}
                      thousand={true}
                      integer={8}
                      separator={'.'}
                      fraction={2}
                      placeHolder={'0.00'}
                      withSymbol={true}
                      errorMessage={this.getErrorMessage(FIELDS_NAME.ADDITIONAL_REBATES,FIELDS_NAME.AMOUNT,index)}
                      disabled={readOnly}
                      classWrapperInput={readOnly ? 'input-disabled' : ''}
                    />
                    {
                      !readOnly && (
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                  className="icon-delete-content" beforeInjection={ svg => svg.classList.add("icon-delete")}
                                  onClick={(evt) =>
                                    this.deleteAdditional(FIELDS_NAME.ADDITIONAL_REBATES,index, evt)}
                        />
                      )
                    }
                  </div>
                )
              })
            }
            {
              !readOnly && (
                <div className={'balance-row'}>
                  <button className={'btn-primary btn-add-accessory'}
                          onClick={(evt) =>
                            this.addAdditional(FIELDS_NAME.ADDITIONAL_REBATES, getSaleAdditionalInitial(ADDITIONAL_TYPE.REBATE),evt)}>
                    + Add Rebate
                  </button>
                </div>
              )
            }
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                classWrapperInput={'input-disabled'}
                title={'Subtotal'}
                id={'input-rebate-subtotal'}
                value={additionalRebateSubtotal}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={true}
                withSymbol={true}
                classTitle='bold-label'
              />
            </div>
          </div>
          <div className={'balance-price'}>
            <h3>Trade-in and Credits</h3>
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                classWrapperInput={'input-disabled'}
                title={SALES_BALANCE_ITEM_LABELS.TRADE_IN_ALLOWANCE}
                id={'input-tradeInAllowance'}
                value={tradeInAllowance}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={true}
                withSymbol={true}
              />
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_sign_question.svg"}
                        className="icon-delete-content"
                        onClick={(evt) => {}}
              />
            </div>
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                classWrapperInput={'input-disabled'}
                title={SALES_BALANCE_ITEM_LABELS.BALANCE_OWED_ON_TRADE_IN}
                id={'input-balanceOwedOn'}
                value={balanceOwedOn}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={true}
                withSymbol={true}
                classWrapperSymbol={'subtract'}
              />
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_sign_question.svg"}
                        className="icon-delete-content"
                        onClick={(evt) => {}}
              />
            </div>
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                title={SALES_BALANCE_ITEM_LABELS.DEPOSIT_CASH_DOWN_PAYMENT}
                id={'input-cashPayment'}
                onChange={(evt, modelValue, viewValue) => this.onChangeFieldValue(FIELDS_NAME.CASH_PAYMENT, modelValue,viewValue,evt)}
                onBlur={(evt) => this.onBlurFieldValue(FIELDS_NAME.CASH_PAYMENT,evt)}
                value={cashPayment}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                withSymbol={true}
                disabled={readOnly}
                classWrapperInput={readOnly ? 'input-disabled' : ''}
                canSelectText={true}
              />
            </div>
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                classWrapperInput={'input-disabled'}
                title={'Subtotal'}
                id={'input-tradeIn-subtotal'}
                value={saleTradeInsSubtotal}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={true}
                withSymbol={true}
                negative={true}
                classWrapperSymbol={'subtract'}
                classTitle='bold-label'
              />
            </div>
          </div>
          <div className={'balance-price'}>
            <h3>Registration fees and other non-taxable items</h3>
            {
              saleRegistrationFees.map((registrationFees, index) => {
                const {id,description,amount, deleteDisabled} = registrationFees;
                return(
                  <div key={`saleRegistrationFees${index}`} className={'balance-row'}>
                    {
                      !id ?
                        <DPInput className={'accessory-desc'}
                                 id={'id'}
                                 type={'text'}
                                 value={description}
                                 onChange={(evt) => this.onChangeAdditional(FIELDS_NAME.REGISTRATION_FEES,FIELDS_NAME.DESCRIPTION,index,evt)} /> :
                        null
                    }
                    <DPDynamicNumberInput
                      className={id ? 'col25' : 'accessory-amount'}
                      title={id ? description : null}
                      id={`input-manufacturer-${index}`}
                      value={amount}
                      onChange={(evt, modelValue, viewValue) =>
                        this.onChangeAdditionalFieldValue(FIELDS_NAME.REGISTRATION_FEES,FIELDS_NAME.AMOUNT,index,modelValue,viewValue,evt)}
                      onBlur={(evt) => this.onBlurAdditionalFieldValue(FIELDS_NAME.REGISTRATION_FEES,FIELDS_NAME.AMOUNT,index,evt)}
                      thousand={true}
                      integer={8}
                      separator={'.'}
                      fraction={2}
                      placeHolder={'0.00'}
                      withSymbol={true}
                      errorMessage={this.getErrorMessage(FIELDS_NAME.AMOUNT)}
                      classWrapperInput={readOnly ? 'input-disabled' : ''}
                      disabled={readOnly}
                      canSelectText={true}
                    />
                    {
                      (!readOnly && !deleteDisabled) && (
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                  className="icon-delete-content" beforeInjection={ svg => svg.classList.add("icon-delete")}
                                  onClick={(evt) =>
                                    this.deleteAdditional(FIELDS_NAME.REGISTRATION_FEES,index, evt)}
                        />
                      )
                    }
                  </div>
                )
              })
            }
            {
              !readOnly && (
                <div className={'balance-row'}>
                  <button className={'btn-primary btn-add-accessory'}
                          onClick={(evt) =>
                            this.addAdditional(FIELDS_NAME.REGISTRATION_FEES, getSaleAdditionalInitial(ADDITIONAL_TYPE.REGISTRATION_FEES),evt)}>
                    + Add other registration fee
                  </button>
                </div>
              )
            }
            <div className={'balance-row'}>
              <DPDynamicNumberInput
                className={'col25'}
                classWrapperInput={'input-disabled'}
                title={'Subtotal'}
                id={'input-registration-fees-subtotal'}
                value={saleRegistrationFeesSubtotal}
                thousand={true}
                integer={8}
                separator={'.'}
                fraction={2}
                placeHolder={'0.00'}
                disabled={true}
                withSymbol={true}
                classTitle='bold-label'
              />
            </div>
          </div>
        </div>
        <BalanceSummary
          carPrice={carPrice}
          accessoryServiceSubtotal={accessoryServiceSubtotal}
          additionalRebateSubtotal={additionalRebateSubtotal}
          saleTradeInsSubtotal={saleTradeInsSubtotal}
          saleRegistrationFeesSubtotal={saleRegistrationFeesSubtotal}
          tradeInAllowance={tradeInAllowance}
          stateTaxPercentage={stateTaxPercentage}
          saleStateTax={saleStateTax}
          setIsTaxExempt={this.setIsTaxExempt}
          setTax={this.setTax}
          isTaxExempt={isTaxExempt}
          readOnlySale={readOnly}
        />
      </div>
    );
  }
}